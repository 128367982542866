import * as rudderanalytics from 'rudder-sdk-js';
import type { apiCallback, apiObject, apiOptions } from 'rudder-sdk-js';
import getSessionReplayProperties from '~/helpers/getSessionReplayProperties';

export default defineNuxtPlugin(async () => {
  const config = useRuntimeConfig();

  rudderanalytics.load(
    config.public.rudderstackWriteKey,
    config.public.rudderstackDataPlane
  );

  rudderanalytics.ready(() => {
    console.info('Rudderstack Ready');
  });

  const deviceId = rudderanalytics.getAnonymousId();

  const getSessionId = () => {
    return rudderanalytics.getSessionId();
  };

  useState('deviceId', () => deviceId);

  const identifyEvent = (
    userId: string,
    traits: apiObject,
    options?: apiOptions
  ) => {
    if (rudderanalytics) {
      console.info('Identify event | ', userId, traits, options || {});
      rudderanalytics.identify(userId, traits, options);
    }
  };

  const trackEvent = (event: string, data?: Record<string, any>) => {
    const properties = getSessionReplayProperties(getSessionId());
    const computedData = properties ? { ...data, ...properties } : data;

    if (rudderanalytics) {
      console.info('Track event | ', event, computedData || {});
      rudderanalytics.track(event, computedData);
    }
  };

  const resetEvent = () => {
    if (rudderanalytics) {
      console.info('Reset event | ');
      rudderanalytics.reset(true);
    }
  };

  const pageEvent = ({
    name,
    category,
    properties,
    options,
    callback,
  }: {
    name?: string;
    category?: string;
    properties?: apiObject;
    options?: apiOptions;
    callback?: apiCallback;
  }) => {
    if (rudderanalytics) {
      console.info('Page event');
      rudderanalytics.page(category, name, properties, options, callback);
    }
  };

  return {
    provide: {
      trackEvent,
      identifyEvent,
      resetEvent,
      pageEvent,
      getSessionId,
    },
  };
});

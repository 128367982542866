import { default as details7dt4pYQk9QMeta } from "/home/runner/work/taste-network/taste-network/packages/web-app/pages/brands/account/details.vue?macro=true";
import { default as indexRu9ZKE3ifsMeta } from "/home/runner/work/taste-network/taste-network/packages/web-app/pages/brands/account/index.vue?macro=true";
import { default as index7Cj5RBpO6IMeta } from "/home/runner/work/taste-network/taste-network/packages/web-app/pages/brands/dashboard/index.vue?macro=true";
import { default as ratingsrKOz1k122rMeta } from "/home/runner/work/taste-network/taste-network/packages/web-app/pages/brands/dashboard/ratings.vue?macro=true";
import { default as reviews0xAYkTRLV6Meta } from "/home/runner/work/taste-network/taste-network/packages/web-app/pages/brands/dashboard/reviews.vue?macro=true";
import { default as index5BMmmG2KUrMeta } from "/home/runner/work/taste-network/taste-network/packages/web-app/pages/brands/orders/index.vue?macro=true";
import { default as archivedlydkzSx1nzMeta } from "/home/runner/work/taste-network/taste-network/packages/web-app/pages/brands/products/archived.vue?macro=true";
import { default as _91productId_93zZ06AbBHTOMeta } from "/home/runner/work/taste-network/taste-network/packages/web-app/pages/brands/products/edit/[productId].vue?macro=true";
import { default as indexhKWZG7IgyLMeta } from "/home/runner/work/taste-network/taste-network/packages/web-app/pages/brands/products/index.vue?macro=true";
import { default as signupUBKOrf8EKCMeta } from "/home/runner/work/taste-network/taste-network/packages/web-app/pages/brands/signup.vue?macro=true";
import { default as helpyFoVlJB94GMeta } from "/home/runner/work/taste-network/taste-network/packages/web-app/pages/login/help.vue?macro=true";
import { default as indexf8V4vm1fsPMeta } from "/home/runner/work/taste-network/taste-network/packages/web-app/pages/login/index.vue?macro=true";
import { default as magicHsby3aGn34Meta } from "/home/runner/work/taste-network/taste-network/packages/web-app/pages/login/magic.vue?macro=true";
import { default as checkout9f6juQbjmLMeta } from "/home/runner/work/taste-network/taste-network/packages/web-app/pages/members/checkout.vue?macro=true";
import { default as how_45it_45worksWxpozaUFobMeta } from "/home/runner/work/taste-network/taste-network/packages/web-app/pages/members/how-it-works.vue?macro=true";
import { default as indexdXptrUx01JMeta } from "/home/runner/work/taste-network/taste-network/packages/web-app/pages/members/index.vue?macro=true";
import { default as _91id_93l8GS7peGuiMeta } from "/home/runner/work/taste-network/taste-network/packages/web-app/pages/members/onboarding/[id].vue?macro=true";
import { default as signup3WZa8HjPSzMeta } from "/home/runner/work/taste-network/taste-network/packages/web-app/pages/members/onboarding/signup.vue?macro=true";
import { default as startbGZSOFZ6CRMeta } from "/home/runner/work/taste-network/taste-network/packages/web-app/pages/members/onboarding/start.vue?macro=true";
import { default as _91ids_93obDBKLELUDMeta } from "/home/runner/work/taste-network/taste-network/packages/web-app/pages/members/orders/confirmation/[ids].vue?macro=true";
import { default as _91id_93c6ZpNnXzEBMeta } from "/home/runner/work/taste-network/taste-network/packages/web-app/pages/members/products/[id].vue?macro=true";
import { default as index6qLuYlFNuKMeta } from "/home/runner/work/taste-network/taste-network/packages/web-app/pages/members/products/index.vue?macro=true";
import { default as promotionsLX6Pc0YvGvMeta } from "/home/runner/work/taste-network/taste-network/packages/web-app/pages/members/promotions.vue?macro=true";
import { default as _91id_93fXc8cfzj7wMeta } from "/home/runner/work/taste-network/taste-network/packages/web-app/pages/members/review/[productId]/[id].vue?macro=true";
import { default as startN9h8lK7hNlMeta } from "/home/runner/work/taste-network/taste-network/packages/web-app/pages/members/review/[productId]/start.vue?macro=true";
import { default as thank_45yougDtOnZrfqBMeta } from "/home/runner/work/taste-network/taste-network/packages/web-app/pages/members/review/[productId]/thank-you.vue?macro=true";
import { default as walletcpFODNgA2wMeta } from "/home/runner/work/taste-network/taste-network/packages/web-app/pages/members/wallet.vue?macro=true";
import { default as component_45stubK8fjNyHV6PMeta } from "/home/runner/work/taste-network/taste-network/node_modules/nuxt/dist/pages/runtime/component-stub?macro=true";
import { default as component_45stubK8fjNyHV6P } from "/home/runner/work/taste-network/taste-network/node_modules/nuxt/dist/pages/runtime/component-stub";
export default [
  {
    name: details7dt4pYQk9QMeta?.name ?? "brands-account-details",
    path: details7dt4pYQk9QMeta?.path ?? "/brands/account/details",
    meta: details7dt4pYQk9QMeta || {},
    alias: details7dt4pYQk9QMeta?.alias || [],
    redirect: details7dt4pYQk9QMeta?.redirect,
    component: () => import("/home/runner/work/taste-network/taste-network/packages/web-app/pages/brands/account/details.vue").then(m => m.default || m)
  },
  {
    name: indexRu9ZKE3ifsMeta?.name ?? "brands-account",
    path: indexRu9ZKE3ifsMeta?.path ?? "/brands/account",
    meta: indexRu9ZKE3ifsMeta || {},
    alias: indexRu9ZKE3ifsMeta?.alias || [],
    redirect: indexRu9ZKE3ifsMeta?.redirect,
    component: () => import("/home/runner/work/taste-network/taste-network/packages/web-app/pages/brands/account/index.vue").then(m => m.default || m)
  },
  {
    name: index7Cj5RBpO6IMeta?.name ?? "brands-dashboard",
    path: index7Cj5RBpO6IMeta?.path ?? "/brands/dashboard",
    meta: index7Cj5RBpO6IMeta || {},
    alias: index7Cj5RBpO6IMeta?.alias || [],
    redirect: index7Cj5RBpO6IMeta?.redirect,
    component: () => import("/home/runner/work/taste-network/taste-network/packages/web-app/pages/brands/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: ratingsrKOz1k122rMeta?.name ?? "brands-dashboard-ratings",
    path: ratingsrKOz1k122rMeta?.path ?? "/brands/dashboard/ratings",
    meta: ratingsrKOz1k122rMeta || {},
    alias: ratingsrKOz1k122rMeta?.alias || [],
    redirect: ratingsrKOz1k122rMeta?.redirect,
    component: () => import("/home/runner/work/taste-network/taste-network/packages/web-app/pages/brands/dashboard/ratings.vue").then(m => m.default || m)
  },
  {
    name: reviews0xAYkTRLV6Meta?.name ?? "brands-dashboard-reviews",
    path: reviews0xAYkTRLV6Meta?.path ?? "/brands/dashboard/reviews",
    meta: reviews0xAYkTRLV6Meta || {},
    alias: reviews0xAYkTRLV6Meta?.alias || [],
    redirect: reviews0xAYkTRLV6Meta?.redirect,
    component: () => import("/home/runner/work/taste-network/taste-network/packages/web-app/pages/brands/dashboard/reviews.vue").then(m => m.default || m)
  },
  {
    name: index5BMmmG2KUrMeta?.name ?? "brands-orders",
    path: index5BMmmG2KUrMeta?.path ?? "/brands/orders",
    meta: index5BMmmG2KUrMeta || {},
    alias: index5BMmmG2KUrMeta?.alias || [],
    redirect: index5BMmmG2KUrMeta?.redirect,
    component: () => import("/home/runner/work/taste-network/taste-network/packages/web-app/pages/brands/orders/index.vue").then(m => m.default || m)
  },
  {
    name: archivedlydkzSx1nzMeta?.name ?? "brands-products-archived",
    path: archivedlydkzSx1nzMeta?.path ?? "/brands/products/archived",
    meta: archivedlydkzSx1nzMeta || {},
    alias: archivedlydkzSx1nzMeta?.alias || [],
    redirect: archivedlydkzSx1nzMeta?.redirect,
    component: () => import("/home/runner/work/taste-network/taste-network/packages/web-app/pages/brands/products/archived.vue").then(m => m.default || m)
  },
  {
    name: _91productId_93zZ06AbBHTOMeta?.name ?? "brands-products-edit-productId",
    path: _91productId_93zZ06AbBHTOMeta?.path ?? "/brands/products/edit/:productId()",
    meta: _91productId_93zZ06AbBHTOMeta || {},
    alias: _91productId_93zZ06AbBHTOMeta?.alias || [],
    redirect: _91productId_93zZ06AbBHTOMeta?.redirect,
    component: () => import("/home/runner/work/taste-network/taste-network/packages/web-app/pages/brands/products/edit/[productId].vue").then(m => m.default || m)
  },
  {
    name: indexhKWZG7IgyLMeta?.name ?? "brands-products",
    path: indexhKWZG7IgyLMeta?.path ?? "/brands/products",
    meta: indexhKWZG7IgyLMeta || {},
    alias: indexhKWZG7IgyLMeta?.alias || [],
    redirect: indexhKWZG7IgyLMeta?.redirect,
    component: () => import("/home/runner/work/taste-network/taste-network/packages/web-app/pages/brands/products/index.vue").then(m => m.default || m)
  },
  {
    name: signupUBKOrf8EKCMeta?.name ?? "brands-signup",
    path: signupUBKOrf8EKCMeta?.path ?? "/brands/signup",
    meta: signupUBKOrf8EKCMeta || {},
    alias: signupUBKOrf8EKCMeta?.alias || [],
    redirect: signupUBKOrf8EKCMeta?.redirect,
    component: () => import("/home/runner/work/taste-network/taste-network/packages/web-app/pages/brands/signup.vue").then(m => m.default || m)
  },
  {
    name: helpyFoVlJB94GMeta?.name ?? "login-help",
    path: helpyFoVlJB94GMeta?.path ?? "/login/help",
    meta: helpyFoVlJB94GMeta || {},
    alias: helpyFoVlJB94GMeta?.alias || [],
    redirect: helpyFoVlJB94GMeta?.redirect,
    component: () => import("/home/runner/work/taste-network/taste-network/packages/web-app/pages/login/help.vue").then(m => m.default || m)
  },
  {
    name: indexf8V4vm1fsPMeta?.name ?? "login",
    path: indexf8V4vm1fsPMeta?.path ?? "/login",
    meta: indexf8V4vm1fsPMeta || {},
    alias: indexf8V4vm1fsPMeta?.alias || [],
    redirect: indexf8V4vm1fsPMeta?.redirect,
    component: () => import("/home/runner/work/taste-network/taste-network/packages/web-app/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: magicHsby3aGn34Meta?.name ?? "login-magic",
    path: magicHsby3aGn34Meta?.path ?? "/login/magic",
    meta: magicHsby3aGn34Meta || {},
    alias: magicHsby3aGn34Meta?.alias || [],
    redirect: magicHsby3aGn34Meta?.redirect,
    component: () => import("/home/runner/work/taste-network/taste-network/packages/web-app/pages/login/magic.vue").then(m => m.default || m)
  },
  {
    name: checkout9f6juQbjmLMeta?.name ?? "members-checkout",
    path: checkout9f6juQbjmLMeta?.path ?? "/members/checkout",
    meta: checkout9f6juQbjmLMeta || {},
    alias: checkout9f6juQbjmLMeta?.alias || [],
    redirect: checkout9f6juQbjmLMeta?.redirect,
    component: () => import("/home/runner/work/taste-network/taste-network/packages/web-app/pages/members/checkout.vue").then(m => m.default || m)
  },
  {
    name: how_45it_45worksWxpozaUFobMeta?.name ?? "members-how-it-works",
    path: how_45it_45worksWxpozaUFobMeta?.path ?? "/members/how-it-works",
    meta: how_45it_45worksWxpozaUFobMeta || {},
    alias: how_45it_45worksWxpozaUFobMeta?.alias || [],
    redirect: how_45it_45worksWxpozaUFobMeta?.redirect,
    component: () => import("/home/runner/work/taste-network/taste-network/packages/web-app/pages/members/how-it-works.vue").then(m => m.default || m)
  },
  {
    name: indexdXptrUx01JMeta?.name ?? "members",
    path: indexdXptrUx01JMeta?.path ?? "/members",
    meta: indexdXptrUx01JMeta || {},
    alias: indexdXptrUx01JMeta?.alias || [],
    redirect: indexdXptrUx01JMeta?.redirect,
    component: () => import("/home/runner/work/taste-network/taste-network/packages/web-app/pages/members/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93l8GS7peGuiMeta?.name ?? "members-onboarding-id",
    path: _91id_93l8GS7peGuiMeta?.path ?? "/members/onboarding/:id()",
    meta: _91id_93l8GS7peGuiMeta || {},
    alias: _91id_93l8GS7peGuiMeta?.alias || [],
    redirect: _91id_93l8GS7peGuiMeta?.redirect,
    component: () => import("/home/runner/work/taste-network/taste-network/packages/web-app/pages/members/onboarding/[id].vue").then(m => m.default || m)
  },
  {
    name: signup3WZa8HjPSzMeta?.name ?? "members-onboarding-signup",
    path: signup3WZa8HjPSzMeta?.path ?? "/members/onboarding/signup",
    meta: signup3WZa8HjPSzMeta || {},
    alias: signup3WZa8HjPSzMeta?.alias || [],
    redirect: signup3WZa8HjPSzMeta?.redirect,
    component: () => import("/home/runner/work/taste-network/taste-network/packages/web-app/pages/members/onboarding/signup.vue").then(m => m.default || m)
  },
  {
    name: startbGZSOFZ6CRMeta?.name ?? "members-onboarding-start",
    path: startbGZSOFZ6CRMeta?.path ?? "/members/onboarding/start",
    meta: startbGZSOFZ6CRMeta || {},
    alias: startbGZSOFZ6CRMeta?.alias || [],
    redirect: startbGZSOFZ6CRMeta?.redirect,
    component: () => import("/home/runner/work/taste-network/taste-network/packages/web-app/pages/members/onboarding/start.vue").then(m => m.default || m)
  },
  {
    name: _91ids_93obDBKLELUDMeta?.name ?? "members-orders-confirmation-ids",
    path: _91ids_93obDBKLELUDMeta?.path ?? "/members/orders/confirmation/:ids()",
    meta: _91ids_93obDBKLELUDMeta || {},
    alias: _91ids_93obDBKLELUDMeta?.alias || [],
    redirect: _91ids_93obDBKLELUDMeta?.redirect,
    component: () => import("/home/runner/work/taste-network/taste-network/packages/web-app/pages/members/orders/confirmation/[ids].vue").then(m => m.default || m)
  },
  {
    name: _91id_93c6ZpNnXzEBMeta?.name ?? "members-products-id",
    path: _91id_93c6ZpNnXzEBMeta?.path ?? "/members/products/:id()",
    meta: _91id_93c6ZpNnXzEBMeta || {},
    alias: _91id_93c6ZpNnXzEBMeta?.alias || [],
    redirect: _91id_93c6ZpNnXzEBMeta?.redirect,
    component: () => import("/home/runner/work/taste-network/taste-network/packages/web-app/pages/members/products/[id].vue").then(m => m.default || m)
  },
  {
    name: index6qLuYlFNuKMeta?.name ?? "members-products",
    path: index6qLuYlFNuKMeta?.path ?? "/members/products",
    meta: index6qLuYlFNuKMeta || {},
    alias: index6qLuYlFNuKMeta?.alias || [],
    redirect: index6qLuYlFNuKMeta?.redirect,
    component: () => import("/home/runner/work/taste-network/taste-network/packages/web-app/pages/members/products/index.vue").then(m => m.default || m)
  },
  {
    name: promotionsLX6Pc0YvGvMeta?.name ?? "members-promotions",
    path: promotionsLX6Pc0YvGvMeta?.path ?? "/members/promotions",
    meta: promotionsLX6Pc0YvGvMeta || {},
    alias: promotionsLX6Pc0YvGvMeta?.alias || [],
    redirect: promotionsLX6Pc0YvGvMeta?.redirect,
    component: () => import("/home/runner/work/taste-network/taste-network/packages/web-app/pages/members/promotions.vue").then(m => m.default || m)
  },
  {
    name: _91id_93fXc8cfzj7wMeta?.name ?? "members-review-productId-id",
    path: _91id_93fXc8cfzj7wMeta?.path ?? "/members/review/:productId()/:id()",
    meta: _91id_93fXc8cfzj7wMeta || {},
    alias: _91id_93fXc8cfzj7wMeta?.alias || [],
    redirect: _91id_93fXc8cfzj7wMeta?.redirect,
    component: () => import("/home/runner/work/taste-network/taste-network/packages/web-app/pages/members/review/[productId]/[id].vue").then(m => m.default || m)
  },
  {
    name: startN9h8lK7hNlMeta?.name ?? "members-review-productId-start",
    path: startN9h8lK7hNlMeta?.path ?? "/members/review/:productId()/start",
    meta: startN9h8lK7hNlMeta || {},
    alias: startN9h8lK7hNlMeta?.alias || [],
    redirect: startN9h8lK7hNlMeta?.redirect,
    component: () => import("/home/runner/work/taste-network/taste-network/packages/web-app/pages/members/review/[productId]/start.vue").then(m => m.default || m)
  },
  {
    name: thank_45yougDtOnZrfqBMeta?.name ?? "members-review-productId-thank-you",
    path: thank_45yougDtOnZrfqBMeta?.path ?? "/members/review/:productId()/thank-you",
    meta: thank_45yougDtOnZrfqBMeta || {},
    alias: thank_45yougDtOnZrfqBMeta?.alias || [],
    redirect: thank_45yougDtOnZrfqBMeta?.redirect,
    component: () => import("/home/runner/work/taste-network/taste-network/packages/web-app/pages/members/review/[productId]/thank-you.vue").then(m => m.default || m)
  },
  {
    name: walletcpFODNgA2wMeta?.name ?? "members-wallet",
    path: walletcpFODNgA2wMeta?.path ?? "/members/wallet",
    meta: walletcpFODNgA2wMeta || {},
    alias: walletcpFODNgA2wMeta?.alias || [],
    redirect: walletcpFODNgA2wMeta?.redirect,
    component: () => import("/home/runner/work/taste-network/taste-network/packages/web-app/pages/members/wallet.vue").then(m => m.default || m)
  },
  {
    name: component_45stubK8fjNyHV6PMeta?.name ?? undefined,
    path: component_45stubK8fjNyHV6PMeta?.path ?? "/brands",
    meta: component_45stubK8fjNyHV6PMeta || {},
    alias: component_45stubK8fjNyHV6PMeta?.alias || [],
    redirect: component_45stubK8fjNyHV6PMeta?.redirect,
    component: component_45stubK8fjNyHV6P
  },
  {
    name: component_45stubK8fjNyHV6PMeta?.name ?? undefined,
    path: component_45stubK8fjNyHV6PMeta?.path ?? "/onboarding",
    meta: component_45stubK8fjNyHV6PMeta || {},
    alias: component_45stubK8fjNyHV6PMeta?.alias || [],
    redirect: component_45stubK8fjNyHV6PMeta?.redirect,
    component: component_45stubK8fjNyHV6P
  },
  {
    name: component_45stubK8fjNyHV6PMeta?.name ?? undefined,
    path: component_45stubK8fjNyHV6PMeta?.path ?? "/members/onboarding",
    meta: component_45stubK8fjNyHV6PMeta || {},
    alias: component_45stubK8fjNyHV6PMeta?.alias || [],
    redirect: component_45stubK8fjNyHV6PMeta?.redirect,
    component: component_45stubK8fjNyHV6P
  },
  {
    name: component_45stubK8fjNyHV6PMeta?.name ?? undefined,
    path: component_45stubK8fjNyHV6PMeta?.path ?? "/onboarding/signup",
    meta: component_45stubK8fjNyHV6PMeta || {},
    alias: component_45stubK8fjNyHV6PMeta?.alias || [],
    redirect: component_45stubK8fjNyHV6PMeta?.redirect,
    component: component_45stubK8fjNyHV6P
  },
  {
    name: component_45stubK8fjNyHV6PMeta?.name ?? undefined,
    path: component_45stubK8fjNyHV6PMeta?.path ?? "/products",
    meta: component_45stubK8fjNyHV6PMeta || {},
    alias: component_45stubK8fjNyHV6PMeta?.alias || [],
    redirect: component_45stubK8fjNyHV6PMeta?.redirect,
    component: component_45stubK8fjNyHV6P
  },
  {
    name: component_45stubK8fjNyHV6PMeta?.name ?? undefined,
    path: component_45stubK8fjNyHV6PMeta?.path ?? "/checkout",
    meta: component_45stubK8fjNyHV6PMeta || {},
    alias: component_45stubK8fjNyHV6PMeta?.alias || [],
    redirect: component_45stubK8fjNyHV6PMeta?.redirect,
    component: component_45stubK8fjNyHV6P
  },
  {
    name: component_45stubK8fjNyHV6PMeta?.name ?? undefined,
    path: component_45stubK8fjNyHV6PMeta?.path ?? "/how-it-works",
    meta: component_45stubK8fjNyHV6PMeta || {},
    alias: component_45stubK8fjNyHV6PMeta?.alias || [],
    redirect: component_45stubK8fjNyHV6PMeta?.redirect,
    component: component_45stubK8fjNyHV6P
  },
  {
    name: component_45stubK8fjNyHV6PMeta?.name ?? undefined,
    path: component_45stubK8fjNyHV6PMeta?.path ?? "/wallet",
    meta: component_45stubK8fjNyHV6PMeta || {},
    alias: component_45stubK8fjNyHV6PMeta?.alias || [],
    redirect: component_45stubK8fjNyHV6PMeta?.redirect,
    component: component_45stubK8fjNyHV6P
  },
  {
    name: component_45stubK8fjNyHV6PMeta?.name ?? undefined,
    path: component_45stubK8fjNyHV6PMeta?.path ?? "/members/login",
    meta: component_45stubK8fjNyHV6PMeta || {},
    alias: component_45stubK8fjNyHV6PMeta?.alias || [],
    redirect: component_45stubK8fjNyHV6PMeta?.redirect,
    component: component_45stubK8fjNyHV6P
  }
]
import type { FilterItem } from '~/stores/brandDashboardStore';

export const userTypeOptions: FilterItem[] = [
  {
    label: 'Ordered Sample',
    value: 'orderedSample',
  },
  {
    label: 'Completed Review',
    value: 'completedReview',
  },
];

export const DEFAULT_USER_TYPE = userTypeOptions[0];

export const shoppingHabits: FilterItem[] = [
  {
    label: 'Supermarket chain',
    value: 'supermarketChain',
  },
  {
    label: 'Healthfood supermarket chain',
    value: 'supermarketHealthFood',
  },

  {
    label: 'Local independent grocery store',
    value: 'localGroceryStore',
  },

  {
    label: "Farmer's market",
    value: 'farmerMarket',
  },

  {
    label: 'Warehouse club',
    value: 'warehouseClub',
  },

  {
    label: 'Online grocery delivery service',
    value: 'onlineGrocery',
  },

  {
    label: 'Convenience store',
    value: 'convenienceStore',
  },

  {
    label: 'Specialty food stores',
    value: 'specialityFoodStore',
  },

  {
    label: 'Other',
    value: 'other',
  },
];

export const reactions: FilterItem[] = [
  {
    label: 'GI discomfort',
    value: 'digestiveDiscomfort',
  },

  {
    label: 'Brain fog',
    value: 'brainFog',
  },

  {
    label: 'Glucose spike',
    value: 'glucoseSpike',
  },

  {
    label: 'None',
    value: 'none',
  },

  {
    label: 'Other',
    value: 'other',
  },
];

export const allergies: FilterItem[] = [
  {
    label: 'None',
    value: 'none',
  },
  {
    label: 'Peanuts',
    value: 'peanuts',
  },

  {
    label: 'Tree nuts',
    value: 'treeNuts',
  },

  {
    label: 'Dairy',
    value: 'dairy',
  },

  {
    label: 'Eggs',
    value: 'eggs',
  },

  {
    label: 'Wheat',
    value: 'wheat',
  },

  {
    label: 'Gluten',
    value: 'gluten',
  },

  {
    label: 'Soy',
    value: 'soy',
  },

  {
    label: 'Fish',
    value: 'fish',
  },

  {
    label: 'Shellfish',
    value: 'shellfish',
  },

  {
    label: 'Sesame',
    value: 'sesame',
  },

  {
    label: 'Other',
    value: 'other',
  },
];

export const productRatingCategories: FilterItem[] = [
  { label: 'Overall Product Rating', value: 'overallRating' },
  { label: 'Packaging', value: 'packagingRate' },
  { label: 'Flavor', value: 'flavorRate' },
  { label: 'Texture', value: 'textureRate' },
  { label: 'Price', value: 'priceRate' },
  { label: 'Differentiation', value: 'uniqueProductConsideration' },
];

export const ages: FilterItem[] = [
  { value: '18-24', label: '18-24', metadata: { min: 18, max: 24 } },
  { value: '25-34', label: '25-34', metadata: { min: 25, max: 34 } },
  { value: '35-44', label: '35-44', metadata: { min: 35, max: 44 } },
  { value: '45-54', label: '45-54', metadata: { min: 45, max: 54 } },
  { value: '55-64', label: '55-64', metadata: { min: 55, max: 64 } },
  { value: '65-74', label: '65-74', metadata: { min: 65, max: 74 } },
  { value: '75+', label: '75+', metadata: { min: 75, max: Infinity } },
];

export const genders: FilterItem[] = [
  {
    label: 'Male',
    value: 'male',
  },

  {
    label: 'Female',
    value: 'female',
  },

  {
    label: 'Non-Binary',
    value: 'nonBinary',
  },

  {
    label: 'Prefer Not To Say',
    value: 'preferNotSay',
  },
];

export const diets: FilterItem[] = [
  {
    label: 'Keto',
    value: 'keto',
  },
  {
    label: 'Low Carb',
    value: 'lowCarb',
  },

  {
    label: 'Carnivore',
    value: 'carnivore',
  },

  {
    label: 'Paleo',
    value: 'paleo',
  },

  {
    label: 'Vegetarian',
    value: 'vegetarian',
  },

  {
    label: 'Flexitarian',
    value: 'flexitarian',
  },

  {
    label: 'Pescatarian',
    value: 'pescatarian',
  },

  {
    label: 'Vegan',
    value: 'vegan',
  },

  {
    label: 'Other',
    value: 'other',
  },

  {
    label: 'None',
    value: 'none',
  },
];

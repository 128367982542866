import { useStorage } from '@vueuse/core';
import type {
  Address,
  Order,
  UserOnboardingData,
  Invitation,
  TnError,
  ProductSurveyAnswers,
} from '@tn/shared';
import {
  getFunctions,
  httpsCallable,
  // connectFunctionsEmulator,
} from 'firebase/functions';

export const getFirebaseFunctions = () => {
  const firebaseApp = useFirebaseApp();
  const firebaseFunctions = getFunctions(firebaseApp);

  // TODO: make this configurable
  if (location?.hostname === 'localhost') {
    // uncomment this if you want to use the emulator locally
    // connectFunctionsEmulator(firebaseFunctions, 'localhost', 5001);
  }

  return firebaseFunctions;
};

export const verifyInvitation = async ({
  inviteToken,
}: {
  inviteToken: string;
}) => {
  return httpsCallable<
    unknown,
    {
      success: boolean;
      invitation?: Invitation;
      error?: TnError;
    }
  >(
    getFirebaseFunctions(),
    'verifyInvitation'
  )({ inviteToken });
};

export const postSignup = async ({
  email,
  password,
  inviteToken,
  onboarding,
}: {
  email: string;
  password: string;
  inviteToken: string;
  onboarding: UserOnboardingData;
}) => {
  return httpsCallable<unknown, { success: boolean; error?: TnError }>(
    getFirebaseFunctions(),
    'signup'
  )({ email, password, inviteToken, onboarding });
};

export const syncPremiumStatus = async (lastSyncThresholdMs?: number) => {
  const lastSyncedTimeAtMs = useStorage('lastSyncedTimeAtMs', 0);
  const res = await httpsCallable<
    unknown,
    { success: boolean; error?: TnError }
  >(
    getFirebaseFunctions(),
    'syncPremiumStatus'
  )({
    lastSyncThresholdMs,
  });
  lastSyncedTimeAtMs.value = Date.now();

  return res;
};

export const checkout = async ({
  products,
  shippingAddress,
  saveShippingAddress,
}: {
  products: string[];
  shippingAddress: Address;
  saveShippingAddress: boolean;
}) => {
  return httpsCallable<
    unknown,
    { success: boolean; orders?: Order[]; error?: TnError }
  >(
    getFirebaseFunctions(),
    'checkout'
  )({ products, shippingAddress, saveShippingAddress });
};

export const startProductReview = async (data: {
  productSurvey: Partial<ProductSurveyAnswers>;
}) => {
  const { productSurvey } = data;

  return httpsCallable<
    unknown,
    { success: boolean; docId: string | undefined; error?: TnError }
  >(
    getFirebaseFunctions(),
    'startProductReview'
  )({ productSurvey });
};

export const endProductReview = async (data: { reviewId: string }) => {
  const { reviewId } = data;

  return httpsCallable<
    unknown,
    { success: boolean; completedAtTimeMs: number | undefined; error?: TnError }
  >(
    getFirebaseFunctions(),
    'endProductReview'
  )({ reviewId });
};

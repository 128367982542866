import { PAGES } from '~/constants/events';

const pageEventsNotTracked: string[] = [
  PAGES.LOGIN,
  PAGES.HOW_IT_WORKS,
  PAGES.PRODUCT_LISTING,
  PAGES.CHECKOUT_STARTED,
  PAGES.PRODUCT,
  PAGES.ONBOARDING_STEP,
  PAGES.ONBOARDING_SIGNUP,
  PAGES.PROMOTIONS,
];

export default defineNuxtRouteMiddleware(async (to, from) => {
  if (!process.client) {
    return;
  }

  if (to.fullPath !== from.fullPath && to.path === from.path) {
    return;
  }

  const { $pageEvent } = useNuxtApp();

  const hasQueryParameters = Object.keys(to.query).length > 0;
  const search = hasQueryParameters
    ? '?' + new URLSearchParams(to.query as Record<string, string>).toString()
    : '';

  const baseUrl = `${window.location.protocol}//${window.location.host}`;
  const currentPageFullURL = `${baseUrl}${to.fullPath ?? ''}`;
  const previousPageFullURL = `${baseUrl}${from.fullPath ?? ''}`;

  const eventPageName: string =
    (to.meta.eventPageName as string | undefined) ?? '';

  const properties: {
    referrer: string;
    path: string;
    page?: string;
    search?: string;
    url?: string;
  } = {
    referrer: previousPageFullURL,
    path: to.path ?? '',
    search: search,
    url: currentPageFullURL,
    page: eventPageName,
  };

  if (pageEventsNotTracked.includes(eventPageName)) {
    return;
  }

  $pageEvent({
    name: eventPageName,
    properties,
  });
});

<script setup lang="ts">
// TODO: this import fails
// import { NuxtError } from 'nuxt/app'
import { computed } from 'vue';
import { useRoute } from 'vue-router';

const props = defineProps({
  // error: Object as () => NuxtError,
  // eslint-disable-next-line vue/require-default-prop
  error: Object,
});

const isNotFound = computed(() => props.error?.statusCode === 404);
const isInternalError = computed(() => props.error?.statusCode === 500);

const route = useRoute();
</script>

<template>
  <NuxtLayout>
    <!-- TODO: apply real design to this page (I just made it up) -->
    <UContainer class="my-8 h-screen">
      <div class="flex flex-col items-center justify-center gap-5">
        <TnHeading>
          {{ error?.statusCode || 404 }}
        </TnHeading>
        <p v-if="isNotFound" class="text-xl font-bold">
          Page not found: {{ route.path }}
        </p>
        <p v-if="isInternalError && error?.message" class="text-xl font-bold">
          {{ error.message }}
        </p>

        <UButton color="primary" variant="solid" size="lg" to="/">
          Go back home
        </UButton>
      </div>
    </UContainer>
  </NuxtLayout>
</template>

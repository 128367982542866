export default {
  brands: () => import("/home/runner/work/taste-network/taste-network/packages/web-app/layouts/brands.vue").then(m => m.default || m),
  default: () => import("/home/runner/work/taste-network/taste-network/packages/web-app/layouts/default.vue").then(m => m.default || m),
  "green-shades": () => import("/home/runner/work/taste-network/taste-network/packages/web-app/layouts/green-shades.vue").then(m => m.default || m),
  green: () => import("/home/runner/work/taste-network/taste-network/packages/web-app/layouts/green.vue").then(m => m.default || m),
  "onboarding-base": () => import("/home/runner/work/taste-network/taste-network/packages/web-app/layouts/onboarding-base.vue").then(m => m.default || m),
  product: () => import("/home/runner/work/taste-network/taste-network/packages/web-app/layouts/product.vue").then(m => m.default || m),
  "products-base": () => import("/home/runner/work/taste-network/taste-network/packages/web-app/layouts/products-base.vue").then(m => m.default || m),
  promotions: () => import("/home/runner/work/taste-network/taste-network/packages/web-app/layouts/promotions.vue").then(m => m.default || m),
  "review-base": () => import("/home/runner/work/taste-network/taste-network/packages/web-app/layouts/review-base.vue").then(m => m.default || m),
  sky: () => import("/home/runner/work/taste-network/taste-network/packages/web-app/layouts/sky.vue").then(m => m.default || m)
}
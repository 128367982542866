import { syncPremiumStatus } from '~/utils/firebase';
import { useStorage } from '@vueuse/core';
import useCurrentUserDetails from '~/composables/useCurrentUserDetails';
import ms from 'ms';

export default defineNuxtRouteMiddleware(async (to) => {
  // skip middleware on server
  if (import.meta.server) return;

  const { uid, isBrand } = useCurrentUserDetails();
  const currentTime = Date.now();
  const lastSyncThresholdMs = ms('1h');

  if (uid && !isBrand) {
    const lastSyncedTimeAtMs = useStorage('lastSyncedTimeAtMs', 0);

    // User has already synced within the last hour, skip
    if (
      lastSyncedTimeAtMs.value &&
      currentTime - lastSyncedTimeAtMs.value < lastSyncThresholdMs
    ) {
      return;
    }

    // Sync CM user with TN
    await syncPremiumStatus(lastSyncThresholdMs);
  }
});

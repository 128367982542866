import type { CookieSerializeOptions } from 'cookie-es';

export default function usePreferredLogin() {
  const config = useRuntimeConfig();
  const cookieOpts: CookieSerializeOptions = {
    sameSite: 'lax',
    secure: config.public.appEnv !== 'local',
    expires: new Date(Date.now() + 7 * 24 * 60 * 60 * 1000), // 1 week
  };
  const preferredLoginCookie = useCookie<string>('preferredLogin', cookieOpts);

  function getPreferredLogin(): string {
    return preferredLoginCookie.value || 'password';
  }

  function setPreferredLogin(method: string): void {
    preferredLoginCookie.value = method;
  }

  return {
    getPreferredLogin,
    setPreferredLogin,
  };
}

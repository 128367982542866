import type {
  Exposure,
  ExposureTrackingProvider,
} from '@amplitude/experiment-js-client';

const RudderstackExposureTrackingProvider = (): ExposureTrackingProvider => {
  const { $trackEvent } = useNuxtApp();

  return {
    track: (exposure: Exposure) => {
      $trackEvent('$exposure', exposure);
    },
  };
};

export default RudderstackExposureTrackingProvider;

import isEqual from 'lodash/isEqual';
import { defineStore } from 'pinia';
import { DEFAULT_USER_TYPE } from '~/constants/brandFilters';
import {
  DEFAULT_DASHBOARD_DATA,
  MAX_CYCLES_TO_COMPARE,
  type BrandDashboardData,
} from '~/constants/brandData';
import type { Cycle } from '@tn/shared';

export type FilterItem = {
  label: string;
  value: string;
  isChecked?: boolean;
  metadata?: Record<string, any>;
};

export interface FilterData {
  category: string;
  name: string;
  label: string;
  type: string;
  data: FilterItem | null;
}

interface BrandProducts {
  id: string;
  title: string;
}

interface DashboardCycleData {
  cycleId?: string;
  cycleName?: string;
  data: BrandDashboardData;
}

export const useBrandDashboardStore = defineStore('brandDashboard', () => {
  const abortControllers = new Map<string, AbortController>();
  const brandId = ref<string | undefined>(undefined);
  const pendingFilters = ref<FilterData[]>([]);
  const activeFilters = ref<FilterData[]>([]);
  const activeCycles = ref<Cycle[]>([]);
  const products = ref<BrandProducts[]>([]);
  const dashboardData = shallowRef<BrandDashboardData>(DEFAULT_DASHBOARD_DATA);
  const dashboardCycleData = shallowRef<DashboardCycleData[]>([]);
  const dashboardPreviousCycleData = shallowRef<BrandDashboardData | undefined>(
    undefined
  );
  const hasFetchedProducts = ref(false);
  const { $sentry } = useNuxtApp();
  const isFetchingDashboardData = ref(true);
  const isFetchingDashboardCycleData = ref(false);

  const getAbortController = (purpose: string) => {
    if (abortControllers.has(purpose)) {
      abortControllers.get(purpose)!.abort();
    }
    const controller = new AbortController();
    abortControllers.set(purpose, controller);
    return controller;
  };

  const updateFilterArray = (
    filters: Ref<FilterData[]>,
    data: FilterData,
    shouldRemove: boolean
  ) => {
    const arr = [...filters.value];
    const index = arr.findIndex(
      (filter) => filter.category === data.category && filter.name === data.name
    );

    if (index !== -1) {
      if (shouldRemove) {
        arr.splice(index, 1);
      } else {
        arr[index] = { ...data };
      }
    } else if (!shouldRemove) {
      arr.push({ ...data });
    }

    filters.value = arr;
  };

  const addToPendingFilter = (data: FilterData) => {
    const shouldRemove =
      (data.type === 'checkbox' && !data.data?.isChecked) ||
      (data.type === 'rating' && data.data?.metadata?.ratings?.length === 0) ||
      (data.data?.metadata?.min === 1 && data.data?.metadata?.max === 5);

    updateFilterArray(pendingFilters, data, shouldRemove);
  };

  const clearPendingFilter = () => {
    pendingFilters.value = [];
  };

  const clearAllFilters = () => {
    pendingFilters.value = [];
    activeFilters.value = [];
  };

  const resetPendingFilter = () => {
    pendingFilters.value = [...activeFilters.value];
  };

  const applyFilters = () => {
    activeFilters.value = [...pendingFilters.value];
    clearDashboardCycleCompareData();
  };

  const resetState = () => {
    brandId.value = undefined;
    dashboardData.value = { ...DEFAULT_DASHBOARD_DATA };
    pendingFilters.value = [];
    activeFilters.value = [];
    products.value = [];
    dashboardCycleData.value = [];
    hasFetchedProducts.value = false;
  };

  const removeFilter = (category: string, name: string) => {
    updateFilterArray(
      activeFilters,
      { category, name, label: '', type: '', data: null },
      true
    );
    updateFilterArray(
      pendingFilters,
      { category, name, label: '', type: '', data: null },
      true
    );
  };

  const loadProducts = async () => {
    if (!hasFetchedProducts.value) {
      const { brandUser } = useCurrentUserDetails();

      brandId.value = brandUser?.brand?.id;

      const brandData = brandUser?.brand?.id
        ? await $fetch(
            `/api/brands/${brandUser.brand.id}/products`,
            authenticatedRequestHeaders()
          )
        : null;

      products.value = brandData?.products ?? [];

      if (brandData?.products && brandData.products.length > 0) {
        addToPendingFilter({
          category: 'product',
          name: `product-sku-${brandData.products[0].id}`,
          label: brandData.products[0].title,
          type: 'checkbox',
          data: {
            label: brandData.products[0].title,
            value: brandData.products[0].id,
            isChecked: true,
          },
        });
        applyFilters();
      }

      hasFetchedProducts.value = true;
    }
  };

  interface FetchDashboardOptions {
    startDate?: number;
    endDate?: number;
    shouldDisregardCycleDates?: boolean;
    fetchPurpose?: 'currentCycle' | 'previousCycle';
  }

  const fetchDashboardData = async ({
    startDate,
    endDate,
    shouldDisregardCycleDates = false,
    fetchPurpose = 'currentCycle',
  }: FetchDashboardOptions = {}) => {
    if (process.server || !brandId.value) {
      return;
    }

    const { signal } = getAbortController(fetchPurpose);

    const extractFilterValues = (prefix: string) =>
      activeFilters.value
        .filter((f) => f.name.startsWith(prefix))
        .map((f) => f?.data?.value);

    const productIds = extractFilterValues('product-sku-');
    const age = extractFilterValues('demographic-age-');
    const gender = extractFilterValues('demographic-gender-');
    const diet = extractFilterValues('diet-');
    const allergies = extractFilterValues('health-allergies-');
    const shopping = extractFilterValues('shopping-');

    const ratings = activeFilters.value
      .filter((f) => f.type === 'rating')
      .map((f) => f.data);

    const userType =
      activeFilters.value.find((f) => f.name === 'userType')?.data?.value ??
      DEFAULT_USER_TYPE.value;

    const cycleStartDate = shouldDisregardCycleDates
      ? undefined
      : startDate ??
        activeFilters.value.find((f) => f.name === 'cycle')?.data?.metadata
          ?.startDate;

    const cycleEndDate = shouldDisregardCycleDates
      ? undefined
      : endDate ??
        activeFilters.value.find((f) => f.name === 'cycle')?.data?.metadata
          ?.endDate;

    const query: Record<string, any> = {
      userType,
      ...(cycleStartDate &&
        cycleEndDate && {
          cycleStartDate,
          cycleEndDate,
        }),
      ...(productIds.length > 0 && { productId: productIds }),
      ...(age.length > 0 && { age }),
      ...(gender.length > 0 && { gender }),
      ...(diet.length > 0 && { diet }),
      ...(allergies.length > 0 && { allergies }),
      ...(shopping.length > 0 && { shopping }),
    };

    // Iterate over each rating and add it to the query object
    if (userType === 'completedReview') {
      ratings.forEach((r) => {
        const ratingsArray = r?.metadata?.ratings ?? [];
        if (ratingsArray.length > 0 && r?.value) {
          query[r.value] = ratingsArray.join('|');
        }
      });
    }

    try {
      return await $fetch(`/api/brands/${brandId.value}/dashboard`, {
        ...authenticatedRequestHeaders(),
        query,
        signal,
      });
    } finally {
      abortControllers.delete(fetchPurpose);
    }
  };

  const clearDashboardCycleCompareData = () => {
    dashboardCycleData.value = [];
  };

  const fetchAllCyclesDashboardData = async () => {
    isFetchingDashboardCycleData.value = true;

    try {
      const data = await fetchDashboardData({
        shouldDisregardCycleDates: true,
      });

      if (data) {
        dashboardCycleData.value = [
          {
            cycleId: undefined,
            cycleName: undefined,
            data,
          },
        ];
      }
    } finally {
      isFetchingDashboardCycleData.value = false;
    }
  };

  const fetchDashboardCycleCompareData = async (
    cycle: Cycle,
    removeOnDuplicate?: boolean
  ) => {
    // Check if this cycle ID is already part of the dataset
    const doesExist = dashboardCycleData.value.some(
      (d) => d.cycleId === cycle.id
    );

    if (doesExist) {
      if (removeOnDuplicate) {
        dashboardCycleData.value = dashboardCycleData.value.filter(
          (d) => d.cycleId !== cycle.id
        );

        // User has deselected all cycles, so let's fetch All Cycles
        if (dashboardCycleData.value.length < 1) {
          await fetchAllCyclesDashboardData();
        }
      }

      return;
    }

    // Check if "All Cycles" was selected and remove it
    dashboardCycleData.value = dashboardCycleData.value.filter((d) =>
      Boolean(d.cycleId)
    );

    // If the user already has the max selected, we need to remove the last one
    if (dashboardCycleData.value.length + 1 > MAX_CYCLES_TO_COMPARE) {
      dashboardCycleData.value.pop();
    }

    isFetchingDashboardCycleData.value = true;

    try {
      const data = await fetchDashboardData({
        startDate: cycle?.startDate.toMillis(),
        endDate: cycle?.endDate.toMillis(),
      });

      if (data) {
        dashboardCycleData.value.push({
          cycleId: cycle?.id,
          cycleName: cycle?.label,
          data,
        });

        dashboardCycleData.value = [...dashboardCycleData.value];
      }
    } finally {
      isFetchingDashboardCycleData.value = false;
    }
  };

  const loadDashboardData = async () => {
    isFetchingDashboardData.value = true;

    try {
      const data = await fetchDashboardData();

      if (data) {
        dashboardData.value = data as BrandDashboardData;
        await loadBrandCycleData(data.cycle_numbers ?? []);
        isFetchingDashboardData.value = false;
      }
    } catch (error: any) {
      if (error.name === 'AbortError' || error.name === 'FetchError') {
        console.log('Fetch aborted');
      } else {
        isFetchingDashboardData.value = false;
        throw error; // Rethrow the error if it's not an abort error
      }
    }
  };

  const loadBrandCycleData = async (cycleNumbers: number[]) => {
    if (activeCycles.value.length > 0) {
      return;
    }

    const cycleStore = useCyclesStore();
    const cycles = await cycleStore.fetchCycles();

    activeCycles.value = cycles
      .filter((c) => cycleNumbers.includes(c.cycleNumber))
      .sort((a, b) => a.cycleNumber - b.cycleNumber);
  };

  // Watch the filter value for changes
  watch(
    activeFilters,
    async (newValue, oldValue) => {
      if (isEqual(oldValue, newValue)) return;

      const activeCycleFilter = newValue.find(
        (filter) => filter.category === 'cycle'
      );

      const promises: Promise<any>[] = [loadDashboardData()];

      if (activeCycleFilter) {
        const cycleNumber = activeCycleFilter.data?.metadata?.cycleNumber;
        const cycleIndex = activeCycles.value.findIndex(
          (cycle) => cycle.cycleNumber === cycleNumber
        );

        if (cycleIndex > 0) {
          const previousCycle = activeCycles.value[cycleIndex - 1];
          const previousDataPromise = fetchDashboardData({
            startDate: previousCycle.startDate.toMillis(),
            endDate: previousCycle.endDate.toMillis(),
            fetchPurpose: 'previousCycle',
          });
          promises.push(previousDataPromise);
        } else {
          dashboardPreviousCycleData.value = undefined;
        }
      } else {
        dashboardPreviousCycleData.value = undefined;
      }

      // Scroll to top
      window.scrollTo({ top: 0, behavior: 'smooth' });

      const [, previousDashboardData] = await Promise.all(promises);
      dashboardPreviousCycleData.value = previousDashboardData;
    },
    { deep: true }
  );

  watch(
    dashboardData,
    async (newValue) => {
      const activeCycleFilter = activeFilters.value.find(
        (filter) => filter.category === 'cycle'
      );

      if (activeCycleFilter) {
        dashboardCycleData.value = [
          {
            cycleId: activeCycleFilter.data?.value,
            cycleName: activeCycleFilter.label,
            data: { ...newValue },
          },
        ];
      }
    },
    { deep: true }
  );

  // Watch the dashboardData value for changes
  watch(
    dashboardData,
    async (newValue) => {
      const activeCycleFilter = activeFilters.value.find(
        (filter) => filter.category === 'cycle'
      );
      if (activeCycleFilter) {
        dashboardCycleData.value = [
          {
            cycleId: activeCycleFilter?.data?.value,
            cycleName: activeCycleFilter?.label,
            data: {
              ...newValue,
            },
          },
        ];
      }
    },
    { deep: true }
  );

  const pendingUserTypeSelection = computed(() => {
    return (
      pendingFilters.value.find((obj) => obj.name === 'userType')?.data
        ?.value ?? DEFAULT_USER_TYPE.value
    );
  });

  const activeUserTypeSelection = computed(() => {
    return (
      activeFilters.value.find((obj) => obj.name === 'userType')?.data?.value ??
      DEFAULT_USER_TYPE.value
    );
  });

  const activeCycleSelection = computed(() => {
    return activeFilters.value.find((obj) => obj.name === 'cycle')?.data?.value;
  });

  const visibleActiveFilters = computed(() => {
    const hideCategoryFilters = ['cycle'];
    const hideNameFilters = ['userType'];
    const isFilteringByOrderSample =
      activeUserTypeSelection.value === 'orderedSample';

    return activeFilters.value.filter((filter) => {
      return (
        !hideCategoryFilters.includes(filter.category) &&
        !hideNameFilters.includes(filter.name) &&
        (!isFilteringByOrderSample || filter.type !== 'rating')
      );
    });
  });

  const calculateTrend = (
    current: number,
    previous: number
  ): {
    direction: 'up' | 'down' | 'none';
    percentage: number;
    previous: number;
    amount: number;
  } => {
    const amount = current - previous;

    if (typeof previous !== 'number' || previous === 0) {
      const direction = current > 0 ? 'up' : 'none';
      const percentage = previous === 0 ? 0 : 100;

      return {
        direction,
        percentage,
        previous,
        amount: current,
      };
    }

    const percentage = Math.abs((amount / previous) * 100);
    const direction = amount > 0 ? 'up' : amount < 0 ? 'down' : 'none';

    return {
      direction,
      percentage: parseFloat(percentage.toFixed(2)),
      previous,
      amount,
    };
  };

  const trendingTotalSamplesOrdered = computed(() => {
    if (!dashboardPreviousCycleData.value || !dashboardData.value) {
      return null;
    }

    const previous = dashboardPreviousCycleData.value?.count_ordered ?? 0;
    const current = dashboardData.value?.count_ordered ?? 0;

    return calculateTrend(current, previous);
  });

  const trendingTotalSamplesReviewed = computed(() => {
    if (!dashboardPreviousCycleData.value || !dashboardData.value) {
      return null;
    }

    const previous = dashboardPreviousCycleData.value?.count_reviewed ?? 0;
    const current = dashboardData.value?.count_reviewed ?? 0;

    return calculateTrend(current, previous);
  });

  return {
    activeUserTypeSelection,
    pendingUserTypeSelection,
    activeCycleSelection,
    activeCycles,
    products,
    dashboardData,
    dashboardCycleData,
    dashboardPreviousCycleData,
    trendingTotalSamplesOrdered,
    trendingTotalSamplesReviewed,
    clearDashboardCycleCompareData,
    applyFilters,
    addToPendingFilter,
    resetPendingFilter,
    clearPendingFilter,
    clearAllFilters,
    pendingFilters,
    activeFilters,
    visibleActiveFilters,
    removeFilter,
    resetState,
    calculateTrend,
    loadProducts,
    loadDashboardData,
    fetchDashboardCycleCompareData,
    fetchAllCyclesDashboardData,
    isFetchingDashboardData,
    isFetchingDashboardCycleData,
  };
});

export default defineAppConfig({
  ui: {
    primary: 'yellow',
    gray: 'cool',
    textColor: 'tnNeutral',
    variables: {
      header: {
        height: '48px',
      },
    },
    notifications: {
      position: 'inset-x-1/2 bottom-2 top-auto',
      wrapper: '-translate-x-1/2',
    },
    notification: {
      ring: 'ring-0 border border-b-4 border-black',
      description: 'text-sm text-[#171717] text-left',
      progress: { base: 'hidden' },
    },
    button: {
      base: 'disabled:opacity-[0.3]',
      block: 'w-full flex justify-center items-center',
      inline: 'inline-flex items-center',
      size: {
        xs: 'text-xs font-semibold font-roca',
        sm: 'text-sm font-semibold font-roca',
        md: 'text-base font-roca',
        lg: 'text-base font-roca',
        xl: 'text-base font-roca',
      },
      padding: {
        xs: 'px-4 py-2',
        sm: 'px-4 py-2',
        md: 'px-4 py-1.5',
        lg: 'px-6 py-2',
        xl: 'px-6 py-2.5',
      },
      color: {
        primary: {
          solid:
            'text-[#353535] bg-yellow-500 hover:bg-yellow-400 rounded-full border-black border-x border-t border-b-4 drop-shadow-sm hover:border-b-2 active:border-b hover:!mb-0.5 active:!mb-[3px]',
        },
        secondary: {
          solid:
            'text-[#353535] bg-[#F5F5F5] rounded-full border-black border-x border-t border-b-4 drop-shadow-sm hover:border-b-2 active:border-b hover:!mb-0.5 active:!mb-[3px]',
        },
        tertiary: {
          solid:
            'text-white bg-blue-500 hover:bg-blue-400 rounded-full border-black border-x border-t border-b-4 drop-shadow-sm hover:border-b-2 active:border-b hover:!mb-0.5 active:!mb-[3px]',
        },
      },
      icon: {
        base: 'flex-shrink-0',
        loading: 'animate-spin',
        size: {
          '2xs': 'h-4 w-4',
          xs: 'h-4 w-4',
          sm: 'h-5 w-5',
          md: 'h-5 w-5',
          lg: 'h-5 w-5',
          xl: 'h-6 w-6',
        },
      },
    },
    checkbox: {
      wrapper: 'relative flex items-center py-2',
      base: 'h-5 w-5',
      container: 'flex items-center',
      border: 'border-2 border-tnNeutral-300 dark:border-gray-700',
      color: 'tertiary',
    },

    radio: {
      wrapper: 'relative flex items-center',
      base: 'h-5 w-5',
      color: 'tertiary',
      container: 'flex items-center h-10',
      border: 'border-2 border-tnNeutral-300 dark:border-gray-700',
    },

    select: {
      wrapper: 'relative w-[279px] md:w-[400px]',
      rounded: 'rounded-lg',
      placeholder: 'text-black dark:text-black',
      base: 'relative block w-full disabled:cursor-not-allowed disabled:opacity-75 focus:outline-none border-1 border-b-4 border-b-black',
      icon: {
        base: 'flex-shrink-0 text-black dark:text-black',
      },
    },

    chip: {
      background: 'bg-{color}-300',
      base: 'ring-0',
      position: {
        'top-right': 'top-1 right-0.5 sm:top-1.5',
      },
    },
    badge: {
      rounded: 'rounded-[100px]',
      font: 'font-medium',
      size: {
        sm: 'text-xs px-2 py-0.5',
      },
      color: {
        green: {
          solid: 'border border-green-100 text-green-500 bg-[#D6FFF5]',
        },
        yellow: {
          solid: 'border border-yellow-300 text-neutral-700 bg-yellow-100',
        },
      },
    },

    dropdown: {
      shadow: '2px 4px 0px 0px #000000',
      width: 'w-36',
      ring: 'ring-0 border border-b-4 border-r-4 border-black',
      item: {
        base: 'group flex items-center gap-1.5 w-full justify-center',
      },
      arrow: {
        base: 'invisible before:visible before:block before:rotate-45 before:z-[-1] before:w-2 before:h-2',
        ring: 'before:ring-0 before:border before:border-b-0 before:border-black',
      },
    },

    shadow: 'cartoon',

    modal: {
      overlay: {
        background: 'bg-black/50 dark:bg-gray-800/75',
      },
    },

    slideover: {
      overlay: {
        background: 'bg-black/50 dark:bg-gray-800/75',
      },
    },
  },
});

import type { User } from '@tn/shared';
type UserTraitValues =
  | string
  | number
  | boolean
  | Array<string | number | boolean>;

export function parseUserProperties(
  user: Partial<User>
): Record<string, UserTraitValues> {
  if (!user) {
    return {};
  }

  const traitObject: Record<string, UserTraitValues> = {};

  Object.entries(user).forEach(([key, value]) => {
    if (
      value !== undefined &&
      (typeof value === 'string' ||
        typeof value === 'number' ||
        typeof value === 'boolean' ||
        Array.isArray(value))
    ) {
      traitObject[key] = value;
    }
  });

  return traitObject;
}

import usePreferredLogin from '~/composables/usePreferredLogin';
import useCurrentUserDetails from '~/composables/useCurrentUserDetails';
import * as Sentry from '@sentry/vue';

export default defineNuxtRouteMiddleware(async (to) => {
  const { uid, email, userType, isBrand } = useCurrentUserDetails();

  if (email && uid) {
    Sentry.setUser({ email, id: uid, user_type: userType });
  } else {
    Sentry.setUser(null);
  }

  // Skip middleware for API requests
  if (to.path.startsWith('/api/')) {
    return;
  }

  // Determine what the homepage route would be based on user type
  if (to.path === '/') {
    return navigateTo({
      name: isBrand ? 'brands-dashboard' : 'home',
    });
  }

  // Prevent regular members from viewing the brands pages
  if (to.path.startsWith('/brands') && uid && !isBrand) {
    return navigateTo({
      name: 'home',
    });
  }

  const isOnboarding = to.fullPath.includes('onboarding');
  const isLogin = String(to.name).startsWith('login');
  const isBrandSignup = String(to.name) === 'brands-signup';

  // redirect the user to the login page if not authenticated
  if (
    !uid &&
    !isLogin &&
    to.name !== 'member-reset-password' &&
    !isOnboarding &&
    !isBrandSignup
  ) {
    const { getPreferredLogin } = usePreferredLogin();

    const name = getPreferredLogin() === 'magic' ? 'login-magic' : 'login';

    return navigateTo({
      name,
      query: {
        ...to.query,
        ...(to.fullPath !== '/' &&
          to.fullPath !== '/members' && {
            redirect: to.fullPath,
          }),
      },
    });
  }
  // redirect the user to the homepage if authenticated
  if (uid && (isLogin || isOnboarding)) {
    return navigateTo({
      name: isBrand ? 'brands-dashboard' : 'home',
      query: to.query,
    });
  }
});

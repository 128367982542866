import type { BrandUser, User } from '@tn/shared';

export default function useCurrentUserDetails() {
  const cartStore = useCartStore();
  const brandDashboardStore = useBrandDashboardStore();

  let email: string | null;
  let uid: string;
  let userType: string;
  let isBrand: boolean;
  let brandUser: BrandUser | null;
  let memberUser: User | null;

  if (process.client) {
    uid = useState<string>('uid').value;
    email = useState<string>('email').value;
    userType = useState<string>('userType').value;
    isBrand = useState<boolean>('isBrand').value;
    brandUser = useState<BrandUser | null>('brandUser').value;
    memberUser = useState<User | null>('memberUser').value;
  } else {
    const { $uid, $email, $memberUser, $brandUser, $isBrand, $userType } =
      useNuxtApp();

    isBrand = $isBrand?.value;
    userType = $userType?.value;
    email = $email?.value;
    uid = $uid?.value;
    brandUser = $brandUser?.value;
    memberUser = $memberUser?.value;
  }

  const clearAuthState = () => {
    useState('uid').value = null;
    useState('email').value = null;
    useState('userType').value = null;
    useState('isBrand').value = false;
    useState('brandUser').value = null;
    useState('memberUser').value = null;

    cartStore.clearCart();
    brandDashboardStore.resetState();
  };

  return {
    uid,
    email,
    userType,
    isBrand,
    brandUser,
    memberUser,
    clearAuthState,
  };
}

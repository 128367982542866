<script setup lang="ts">
onMounted(() => {
  initilizeApp();
});

const initilizeApp = () => {};
</script>

<template>
  <NuxtLayout>
    <NuxtPage />

    <UNotifications>
      <template #title="{ title }">
        <!-- eslint-disable-next-line vue/no-v-html -->
        <span v-html="title" />
      </template>

      <template #description="{ description }">
        <!-- eslint-disable-next-line vue/no-v-html -->
        <span v-html="description" />
      </template>
    </UNotifications>
    <UModals />
  </NuxtLayout>
</template>

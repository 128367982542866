import { defineStore } from 'pinia';
import type { Cart, CartItem } from '@tn/shared';
import { useStorage } from '@vueuse/core';
import { useOrdersStore } from '~/stores/ordersStore';

export const useCartStore = defineStore('cart', {
  state: () => ({
    cart: useStorage<Cart>('userCart', { products: [] }),
  }),
  getters: {
    cartItemsCount: (state) => {
      const cartItems = state.cart?.products;

      return cartItems.reduce((acc, item) => {
        return acc + item.quantity;
      }, 0);
    },

    isInCart(state) {
      return (productId: string): boolean => {
        if (state.cart.products.length === 0) {
          return false;
        }

        return state.cart.products.some(
          (cartItem: CartItem) => cartItem.productId === productId
        );
      };
    },

    isEmptyCart(state) {
      return state.cart.products.length === 0;
    },
  },

  actions: {
    addProductToCart(product: CartItem) {
      const ordersStore = useOrdersStore();

      const productExists = this.cart.products.some(
        (item) => item.productId === product.productId
      );

      if (!ordersStore.canOrderProduct(product.productId)) {
        return {
          success: false,
          message: 'Duplicate samples cannot be ordered',
        };
      }

      if (!productExists) {
        this.cart.products.push(product);
        return { success: true, message: 'Great choice! Item added to cart' };
      }

      const existingProductIndex = this.cart.products.findIndex(
        (item) => item.productId === product.productId
      );

      if (existingProductIndex > -1) {
        return {
          success: false,
          message: 'Duplicate samples cannot be ordered',
        };
      }
    },

    removeProductFromCart(productId: string) {
      const productIndex = this.cart.products.findIndex(
        (item) => item.productId === productId
      );
      if (productIndex > -1) {
        this.cart.products.splice(productIndex, 1);
      }
    },

    clearCart() {
      this.cart.products = [];
    },
  },

  hydrate(storeState) {
    const cartStorage = useStorage<Cart>('userCart', { products: [] });
    if (cartStorage.value) {
      storeState.cart = cartStorage.value;
    }
  },
});

import validate from "/home/runner/work/taste-network/taste-network/node_modules/nuxt/dist/pages/runtime/validate.js";
import analytics_45global from "/home/runner/work/taste-network/taste-network/packages/web-app/middleware/analytics.global.ts";
import auth_45global from "/home/runner/work/taste-network/taste-network/packages/web-app/middleware/auth.global.ts";
import sync_45global from "/home/runner/work/taste-network/taste-network/packages/web-app/middleware/sync.global.ts";
import manifest_45route_45rule from "/home/runner/work/taste-network/taste-network/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  analytics_45global,
  auth_45global,
  sync_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "brand-invitation": () => import("/home/runner/work/taste-network/taste-network/packages/web-app/middleware/brandInvitation.ts"),
  "fetch-brand-data": () => import("/home/runner/work/taste-network/taste-network/packages/web-app/middleware/fetchBrandData.ts"),
  onboarding: () => import("/home/runner/work/taste-network/taste-network/packages/web-app/middleware/onboarding.ts"),
  "product-review": () => import("/home/runner/work/taste-network/taste-network/packages/web-app/middleware/productReview.ts")
}
<template>
  <h1 :ui="ui" :class="ui.wrapper" v-bind="attrs">
    <slot />
  </h1>
</template>

<script setup lang="ts">
import type { PropType } from 'vue';

defineOptions({
  inheritAttrs: false,
});

const props = defineProps({
  class: {
    type: [String, Object, Array] as PropType<any>,
    default: undefined,
  },
  ui: {
    type: Object as PropType<Partial<typeof config.value>>,
    default: () => ({}),
  },
  element: {
    type: String as PropType<
      'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p' | 'div'
    >,
    default: 'h1',
  },
});

const config = computed(() => ({
  wrapper:
    'font-poppins sm:text-6xl text-5xl text-[#FDC823] font-black drop-shadow-[1px_3px_black] stroke-black',
}));

const { ui, attrs } = useUI(
  'heading',
  toRef(props, 'ui'),
  config,
  toRef(props, 'class'),
  true
);
</script>

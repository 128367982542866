const useNow = () => {
  const config = useRuntimeConfig();
  const route = useRoute();

  let dateOverride;
  if (
    config.public.appEnv === 'local' ||
    config.public.appEnv === 'development'
  ) {
    dateOverride = route.query?.now?.toString();
  }

  const now = dateOverride ? new Date(`${dateOverride} 00:00:00`) : new Date();

  return now;
};

export default useNow;
